import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "reactstrap";
import axios from "axios";
import RentalCard from "./rentalCard";
import moment from "moment";
import ReactStars from "react-stars";

interface Iprops {
  userId: any;
}

function Bookings(props: Iprops) {
  const [rentals, setRentals] = useState([]);
  const [stars, setStars] = useState(5);
  const [rate, setRate] = useState(5);
  const [comment, setComment] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (props.userId) {
      axios.post("/getBookings", { userId: props.userId }).then(resp => {
        console.log(resp.data);
        setRentals(resp.data);
      });
    } else {
    }
  };

  return (
    <div>
      <h1>Bookings</h1>
      {rentals.map((r: any) => {
        return (
          <div
            style={{
              verticalAlign: "top",
              marginLeft: 10,
              display: "inline-block",
            }}
            key={r.booking._id}
          >
            <RentalCard
              onPressed={(rental: any) => {
                window.open(`/listing/${r.booking.rental._id}`, "_blank");
              }}
              data={r.booking.rental}
            />
            {r.booking.days.length > 0 && (
              <div>
                {r.booking.days.map((d: any) => {
                  return (
                    <p>{moment(d, "MM DD YYYY").format("MMMM DD YYYY")}</p>
                  );
                })}
              </div>
            )}
            {r.booking.hours && (
              <div>
                <p>
                  {moment(r.booking.checkinDate, "MM DD YYYY h:mm a").format(
                    "MMMM DD YYYY h:mm a"
                  )}
                </p>
                <p>{r.booking.hours} hours</p>
              </div>
            )}
            <p>Total: ${r.booking.total}</p>
            {r.booking.receipt && (
              <Button
                onClick={() => {
                  window.open(r.booking.receipt, "_blank");
                }}
              >
                <p
                  style={{
                    color: "blue",
                  }}
                >
                  Receipt
                </p>
              </Button>
            )}
            {r.booking.checkin &&
              r.booking.checkout &&
              r.booking.customerReview && (
                <div>
                  <ReactStars
                    edit={false}
                    count={5}
                    size={24}
                    color2={"#ffd700"}
                    value={r.booking.customerReview.stars}
                  />
                  <p
                    style={{
                      marginTop: 5,
                    }}
                  >
                    "{r.booking.customerReview.comment}"
                  </p>
                </div>
              )}
            {r.booking.checkin &&
              r.booking.checkout &&
              !r.booking.customerReview && (
                <div>
                  <ReactStars
                    count={5}
                    onChange={(rate: any) => {
                      setRate(rate);
                    }}
                    size={24}
                    color2={"#ffd700"}
                    value={rate}
                  />
                  <textarea
                    placeholder="Comments"
                    style={{
                      marginTop: 5,
                      padding: 5,
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "black",
                      height: 150,
                      width: "100%",
                    }}
                    onChange={(e: any) => {
                      setComment(e.target.value);
                    }}
                  ></textarea>
                  <Button
                    style={{
                      padding: 10,
                      width: 150,
                      marginTop: 10,
                      borderRadius: 10,
                      marginLeft: 0,
                    }}
                    onClick={() => {
                      axios
                        .post("/createReview", {
                          stars: rate,
                          comment,
                          id: r.booking._id,
                        })
                        .then(resp => {
                          alert("Review Submitted ");
                          window.location.reload();
                        });
                    }}
                  >
                    Submit
                  </Button>
                  {r.booking.checkin && !r.booking.checkout && (
                    <Button
                      style={{
                        padding: 10,
                        width: 150,
                        marginTop: 20,
                        borderRadius: 10,
                        marginLeft: 0,
                        backgroundColor: "red",
                      }}
                      onPress={() => {
                        axios
                          .post("/checkout", {
                            id: r.booking._id,
                          })
                          .then(resp => {
                            //
                            alert("Checked out ");
                            window.location.reload();
                          });
                      }}
                    >
                      Checkout
                    </Button>
                  )}
                  {!r.booking.checkin && !r.booking.checkout && (
                    <Button
                      style={{
                        padding: 10,
                        width: 150,
                        marginTop: 20,
                        borderRadius: 10,
                        marginLeft: 0,
                        backgroundColor: "green",
                      }}
                      onPress={() => {
                        console.log("checkin");
                        axios
                          .post("/checkin", {
                            id: r.booking._id,
                          })
                          .then(resp => {
                            //
                            alert("Checked in ");
                            window.location.reload();
                          });
                      }}
                    >
                      Checkin
                    </Button>
                  )}
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
}
export default Bookings;
