import React, { useState } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { useAlert } from "react-alert";
const swal = require("@sweetalert/with-react");

const LoginComp = () => {
  const [email, setEmail] = useState("");
  const [forgotEmail, setForgotEmail] = useState<any>(null);
  const [password, setPassword] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [forgot, setForgot] = useState(false);
  const [login, setLogin] = useState(true);

  const loginNow = () => {
    axios
      .post("/login", {
        email,
        password,
      })
      .then(resp => {
        if (resp.data.status) {
          localStorage.setItem("@silla_id", resp.data.userId);
          window.location.reload();
        } else {
          alert(resp.data.message);
        }
      });
  };
  const signup = () => {
    axios
      .post("/signup", {
        email,
      })
      .then(resp => {
        if (resp.data.status) {
          alert(resp.data.message);
          window.location.reload();
        } else {
          alert(resp.data.message);
        }
      });
  };
  const forgotPassword = () => {
    axios
      .post("/forgotPassword", {
        email: forgotEmail,
      })
      .then(resp => {
        setForgot(false);
        if (resp.data.status) {
          alert("Email Sent");
        } else {
          alert(resp.data.message);
        }
      });
  };

  return (
    <div
      style={{
        paddingTop: 60,
        fontFamily: "Poppins",
      }}
    >
      <div
        style={{
          color: "#37474F",
          width: "100%",
          textAlign: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 20,
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            transition: "0.3s",
            padding: 20,
            width: "90%",
            maxWidth: 400,
            margin: "auto",
          }}
        >
          <a href="/">
            <img
              alt=""
              style={{
                verticalAlign: "sub",
                marginLeft: 20,
                marginTop: 10,
                width: 180,
              }}
              src={"../images/logo.svg"}
            />
          </a>
          <input
            placeholder="Email"
            style={{
              width: "90%",
              padding: 5,
              height: 40,
              borderColor: "black",
              borderWidth: 1,
              marginTop: 20,
              fontSize: 18,
              borderRadius: 5,
            }}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <br />
          {login && (
            <div>
              <input
                type="password"
                placeholder="Password"
                style={{
                  width: "90%",
                  marginTop: 20,
                  padding: 5,
                  height: 40,
                  borderColor: "black",
                  borderWidth: 1,
                  fontSize: 18,
                  borderRadius: 5,
                }}
                onChange={e => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          )}
          <br />

          <div>
            <Button
              style={{
                color: "white",
                backgroundColor: "black",
                width: "90%",
              }}
              onClick={() => {
                login ? loginNow() : signup();
              }}
            >
              {login ? "Login" : "Create Account"}
            </Button>
          </div>

          <div>
            {forgot ? (
              <div
                style={{
                  marginTop: 10,
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                <p>
                  Enter the email associated with your account and you will
                  receive a link to reset your password.
                </p>
                <input
                  style={{
                    padding: 5,
                    width: "95%",
                  }}
                  placeholder="Email"
                  onChange={e => {
                    setForgotEmail(e.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    setForgot(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    forgotPassword();
                  }}
                >
                  Submit
                </button>
              </div>
            ) : (
              <div>
                {login && (
                  <p
                    style={{
                      fontWeight: 600,
                      cursor: "pointer",
                      marginTop: 10,
                    }}
                    onClick={() => {
                      setForgot(true);
                    }}
                  >
                    Forgot password?
                  </p>
                )}
              </div>
            )}
          </div>

          <p
            style={{
              fontWeight: 600,
              cursor: "pointer",
            }}
            onClick={() => {
              setLogin(!login);
            }}
          >
            {login
              ? `Don't have an account ? Sign up`
              : `Already have an account ? Login`}
          </p>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default LoginComp;
