import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "reactstrap";
import axios from "axios";
import Header from "./comps/header";
import { filter } from "lodash";
import Booking from "./booking";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { FiHeart } from "react-icons/fi";

interface IProps {
  small: boolean;
}

const containerStyle = {
  borderRadius: 5,
  maxWidth: "90%",
  width: "400px",
  height: "200px",
};

const Detail = (props: IProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC3TKVdufylu3i69Cn0tzPjqJx16hw709E",
  });

  const [rental, setRental] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [booking, setBooking] = useState(false);
  const [done, setDone] = useState(false);
  const [type, setType] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const [favs, setFavs] = useState([]);
  const [price, setPrice] = useState(0);

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    //map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const l = window.location.pathname.split("/");
    const userId = window.localStorage.getItem("@silla_id");
    if (userId) {
      axios
        .post("/getProfile", {
          userId,
        })
        .then(resp => {
          console.log(resp.data);
          if (resp.data.status) {
            setUser(resp.data.user);
            setFavs(resp.data.user.favs);
          }
        });
    }

    axios.post("/getDetail", { id: l[2] }).then(resp => {
      console.log(resp.data.rental);
      console.log(resp.data.rental.rental.location.coordinates[1]);
      console.log(resp.data.rental.rental.location.coordinates[0]);

      if (resp.data.rental.rental) {
        setRental(resp.data.rental);
        setTimeout(() => {
          setLocation({
            lat: resp.data.rental.rental.location.coordinates[1], //30.3322,
            long: resp.data.rental.rental.location.coordinates[0], //-81.6557,
          });
        }, 1000);
      } else {
        console.log("no rental found");
      }
    });
  };

  return (
    <div>
      <Header small={props.small} detail={true} />
      <div
        style={{
          fontFamily: "Poppins",
          height: 1200,
          width: "100%",
          paddingTop: 20,
          backgroundColor: "white",
          textAlign: "center",
        }}
      >
        <Container>
          {rental ? (
            <div>
              {done ? (
                <div>
                  <h2>You're Booked 🎉</h2>
                  <h3>Booking confirmation email sent</h3>
                </div>
              ) : (
                <div>
                  {booking ? (
                    <Booking
                      onCancel={() => {
                        setBooking(false);
                      }}
                      onDone={() => {
                        setBooking(false);
                        setDone(true);
                        window.scrollTo(0, 0);
                      }}
                      price={price}
                      type={type}
                      rental={rental}
                    />
                  ) : (
                    <Card
                      style={{
                        textAlign: "center",
                        padding: 20,
                      }}
                    >
                      {rental && rental.rental && (
                        <div>
                          <div
                            style={{
                              float: "right",
                            }}
                          >
                            {filter(favs, e => e === rental.rental._id).length >
                            0 ? (
                              <Button
                                style={{
                                  borderColor: "transparent",
                                  backgroundColor: "transparent",
                                }}
                                onClick={() => {
                                  if (user) {
                                    axios
                                      .post("/removeFav", {
                                        userId: user._id,
                                        id: rental.rental._id,
                                      })
                                      .then(resp => {
                                        window.location.reload();
                                      });
                                  }
                                }}
                              >
                                <FiHeart
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  size={30}
                                />
                              </Button>
                            ) : (
                              <Button
                                style={{
                                  borderColor: "transparent",
                                  backgroundColor: "transparent",
                                }}
                                onClick={() => {
                                  if (user) {
                                    axios
                                      .post("/createFav", {
                                        userId: user._id,
                                        id: rental.rental._id,
                                      })
                                      .then(resp => {
                                        window.location.reload();
                                      });
                                  } else {
                                    alert("login or create an account");
                                  }
                                }}
                              >
                                <FiHeart
                                  style={{
                                    color: "grey",
                                    cursor: "pointer",
                                  }}
                                  size={30}
                                />
                              </Button>
                            )}
                          </div>
                          <img
                            style={{
                              width: 450,
                              maxWidth: "90%",
                              borderRadius: 5,
                            }}
                            src={
                              rental &&
                              rental.rental &&
                              rental.rental.photos &&
                              rental.rental.photos[0]
                                ? rental.rental.photos[0].url
                                : "/images/placeholder.png"
                            }
                          />
                          <h1
                            style={{
                              color: "black",
                              fontSize: 30,
                            }}
                          >
                            {rental.rental.title} By{" "}
                            {rental.user && rental.user.name}
                          </h1>
                          <br />
                          <h3>{rental.rental.desc}</h3>
                          <h2>Where you work</h2>
                          <h3>{rental.rental.address}</h3>
                          <div className="webkit">
                            {isLoaded && location && location.lat ? (
                              <GoogleMap
                                options={{
                                  disableDefaultUI: true,
                                }}
                                mapContainerStyle={containerStyle}
                                center={{
                                  lat: location.lat,
                                  lng: location.long,
                                }}
                                zoom={10}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                              >
                                <Marker
                                  position={{
                                    lat: location.lat,
                                    lng: location.long,
                                  }}
                                ></Marker>
                              </GoogleMap>
                            ) : null}
                          </div>
                          <div>
                            <h4
                              style={{
                                marginTop: 10,
                                textAlign: "center",
                              }}
                            >
                              Select your rental type
                            </h4>
                            {(rental && rental.rental.hourPrice) ||
                            rental.rental.dayPrice ? (
                              <div>
                                {["day"].map((d, i) => {
                                  return (
                                    <div
                                      key={d}
                                      onClick={() => {
                                        window.scrollTo(0, 0);
                                        setType(d);
                                        setPrice(rental.rental[d + "Price"]);
                                        setBooking(true);
                                      }}
                                      style={{
                                        marginLeft: 0,
                                        cursor: "pointer",
                                        backgroundColor: "#69F0AE",
                                        height: 50,
                                        marginTop: 10,
                                        paddingTop: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 10,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        ${rental.rental[d + "Price"]} / {d}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <button
                                style={{
                                  backgroundColor: "#FF7043",
                                  width: "95%",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: 10,
                                  fontWeight: "bold",
                                  fontSize: 18,
                                  color: "white",
                                  marginLeft: 10,
                                  marginTop: 20,
                                  height: 50,
                                }}
                                onClick={() => {
                                  // openComposer({
                                  //   to: 'jkato@opensill.com',
                                  //   subject: `Request to claim shop ${rental.title}`,
                                  //   body: `Request to claim ${rental.title}`,
                                  // });
                                }}
                              >
                                Claim this shop
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      {/* <div>
              <Button
                style={{
                  padding: 0,
                  backgroundColor: "#7540ee",
                  color: "white",
                  fontSize: 24,
                  width: 350,
                  maxWidth: "90%",
                }}
                onClick={() => {
                  window.location.href = "/account";
                }}
              >
                Get Started
              </Button>
            </div> */}
                    </Card>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <h1>No rental found</h1>
              <Button
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                start searching
              </Button>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default Detail;
