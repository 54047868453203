import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "reactstrap";
interface IProps {
  small: boolean;
}

const OnBoardSuccess = (props: IProps) => {
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {};

  return (
    <div>
      <div
        style={{
          fontFamily: "Poppins",
          height: 1200,
          width: "100%",
          paddingTop: 20,
          backgroundColor: "white",
          textAlign: "center",
        }}
      >
        <Container>
          <Card
            style={{
              textAlign: "center",
              padding: 20,
            }}
          >
            <h1>
              Thank you for onboarding you are all setup to make money on Open
              Silla
            </h1>
            <p>You can go back to the app now</p>
            {/* <div>
              <Button
                style={{
                  padding: 0,
                  backgroundColor: "#7540ee",
                  color: "white",
                  fontSize: 24,
                  width: 350,
                  maxWidth: "90%",
                }}
                onClick={() => {
                  window.location.href = "/account";
                }}
              >
                Get Started
              </Button>
            </div> */}
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default OnBoardSuccess;
