import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "reactstrap";
import axios from "axios";
import RentalCard from "./rentalCard";
interface Iprops {
  userId: any;
}

function Favs(props: Iprops) {
  const [rentals, setRentals] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (props.userId) {
      axios.post("/getFavs", { userId: props.userId }).then(resp => {
        console.log(resp.data);
        setRentals(resp.data);
      });
    } else {
    }
  };

  return (
    <div>
      <h1>Favorites</h1>
      <div>
        {rentals.map((r: any) => {
          return (
            <RentalCard
              onPressed={(rental: any) => {
                console.log(rental);
                window.open(`/listing/${rental._id}`, "_blank");
              }}
              key={r._id}
              data={r.rental}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Favs;
