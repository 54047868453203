import React, { useEffect, useState, useRef } from "react";
import { Card, Container, Button, Col, Row } from "reactstrap";
import axios from "axios";
import moment from "moment";
import FadeIn from "./fadeIn";

import _ from "lodash";
import io from "socket.io-client";
const socket = io("/");

interface IProps {
  userId: any;
}

function Messages(props: IProps) {
  const [userId, setUserId] = useState(null);
  const [chat, setChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [detailReq, setDetailReq] = useState<any>(null);
  const [rec, setRec] = useState<any>(null);
  const [sender, setSender] = useState<any>(null);
  const [thread, setThread] = useState<any>(null);
  const [text, setText] = useState<any>(null);

  const [notifs, setNotifs] = useState([]);
  const [threads, setThreads] = useState([]);
  const [type, setType] = useState("Messages");
  useEffect(() => {
    //
    fetchData();
  }, []);
  const container = useRef<any>(null);
  function scrollToMyRef() {
    const scroll =
      container.current.scrollHeight - container.current.clientHeight;
    container.current.scrollTo(0, scroll);
  }

  const sendMessage = async (text: any) => {
    const m = {
      sender,
      rec,
      message: {
        sender: props.userId,
        rec: props.userId === sender._id ? rec._id : sender._id,
        text,
      },
    };

    axios.post("/createMessage", m).then(resp => {
      setText("");
    });
  };

  const getMessages = async (rec: any, sender: any) => {
    //
    socket.on(props.userId, (data: any) => {
      // check if on current thread
      setMessages(data);
    });
    axios
      .post("/getMessages", {
        rec,
        sender,
      })
      .then(resp => {
        setRec(resp.data.rec);
        setSender(resp.data.sender);
        setThread(resp.data.thread);
        setMessages(
          resp.data.thread && resp.data.thread.messages
            ? resp.data.thread.messages
            : []
        );
        setChat(true);
      });
  };
  const fetchData = async () => {
    const userId: any = await window.localStorage.getItem("@silla_id");
    if (userId) {
      setUserId(userId);
      axios
        .post("/getReqs", {
          userId,
        })
        .then(async resp => {
          console.log(resp.data);
          setDetailReq(null);
          setNotifs(resp.data);
        });

      axios
        .post("/getThreads", {
          userId,
        })
        .then(async resp => {
          console.log(resp.data);
          setThreads(resp.data);
        });
    } else {
    }
  };
  return (
    <div>
      {chat ? (
        <div>
          <h1>chat</h1>
          <Button
            onClick={() => {
              setChat(false);
            }}
          >
            Back
          </Button>
          <div>
            <div>
              <div
                style={{
                  marginLeft: 10,
                  padding: 10,
                  textAlign: "left",
                  borderColor: "black",
                  borderStyle: "solid",
                  borderRadius: 20,
                  borderWidth: 10,
                  marginTop: 10,
                  backgroundColor: "white",
                  width: "90%",
                }}
              >
                <div
                  ref={container}
                  className="chat-scroll"
                  style={{
                    overflowY: "scroll",
                    height: 400,
                  }}
                >
                  {messages &&
                    messages.map((m: any, textIndex) => {
                      return (
                        <FadeIn
                          onMove={() => {
                            scrollToMyRef();
                          }}
                          key={textIndex}
                        >
                          <div style={{ marginTop: 5 }}>
                            <div style={{ display: "inline-block" }}>
                              <img
                                alt=""
                                style={{
                                  height: 40,
                                  width: 40,
                                  display: "inline-block",
                                  borderRadius: 20,
                                }}
                                src={
                                  m.sender === props.userId
                                    ? rec.image
                                      ? rec.image
                                      : "../../images/blank.png"
                                    : sender.image
                                    ? sender.image
                                    : "../../images/blank.png"
                                }
                              />

                              <div
                                style={{
                                  padding: 10,
                                  borderRadius: 20,
                                  marginLeft: 5,
                                  minWidth: 110,
                                  maxWidth: "90%",
                                  lineHeight: 0.25,
                                  display: "inline-block",
                                  backgroundColor: "#FAFAFA",
                                }}
                              >
                                {!chat && (
                                  <p
                                    style={{
                                      lineHeight: 0,
                                      color: "black",
                                      fontWeight: 600,
                                      fontSize: 14,
                                    }}
                                  >
                                    {" "}
                                  </p>
                                )}
                                <p
                                  style={{
                                    lineHeight: 1,
                                    color: "black",
                                    fontSize: 12,
                                  }}
                                >
                                  {" "}
                                  {m.text}{" "}
                                </p>
                                {chat && (
                                  <p style={{ fontSize: 8, lineHeight: 0 }}>
                                    {moment(m.date).format("h:mm a")}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </FadeIn>
                      );
                    })}

                  <div style={{ height: 50, backgroundColor: "transparent" }} />
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "inline-block",
                  }}
                >
                  <form
                    style={{
                      width: "100%",
                      borderWidth: 1,
                      height: 60,
                      paddingTop: 0,
                      borderRadius: 10,
                      borderColor: "grey",
                      borderStyle: "solid",
                      display: "inline-block",
                    }}
                    onSubmit={e => {
                      e.preventDefault();
                      if (text && text !== "") {
                        sendMessage(text);
                        setText("");
                      }
                    }}
                  >
                    <Row>
                      <Col lg="8" md="8" sm="8" xs="8">
                        <input
                          style={{
                            borderColor: "transparent",
                            marginTop: 10,
                            height: 40,
                            width: "100%",
                            color: "black",
                            fontSize: 14,
                            paddingLeft: 10,
                            marginLeft: 10,
                          }}
                          value={text}
                          placeholder="Write a message..."
                          onChange={e => {
                            setText(e.target.value);
                          }}
                        />
                      </Col>
                      <Col lg="2" md="2" sm="2" xs="2">
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "black",
                          }}
                          onClick={() => {
                            if (text && text !== "") {
                              sendMessage(text);
                              setText("");
                            }
                          }}
                        >
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            {["Messages", "Notifications"].map((m: any) => {
              return (
                <Button
                  key={m}
                  style={{
                    backgroundColor: type === m ? "black" : "white",
                    color: type === m ? "white" : "black",
                    display: "inline-block",
                  }}
                  onClick={() => {
                    setType(m);
                  }}
                >
                  {m}
                </Button>
              );
            })}
          </div>

          <div>
            {type === "Notifications" ? (
              <div
                style={{
                  padding: 5,
                }}
              >
                {detailReq ? (
                  <div>
                    <div
                      style={{
                        marginTop: 30,
                      }}
                    >
                      <div
                        style={{
                          flexDirection: "row",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: 10,
                            height: 50,
                            width: 50,
                          }}
                          src={detailReq.rental.photos[0].url}
                        />
                        <div
                          style={{
                            width: 250,
                            marginLeft: 20,
                          }}
                        >
                          <p>{detailReq.rental.title}</p>
                          {detailReq.days.length > 0 && (
                            <div>
                              {detailReq.days.map((d: any) => {
                                return (
                                  <p key={d}>
                                    {moment(d, "MM DD YYYY").format(
                                      "MMMM DD YYYY"
                                    )}
                                  </p>
                                );
                              })}
                            </div>
                          )}
                          {detailReq.hours && (
                            <div>
                              <p>
                                {moment(
                                  detailReq.checkinDate,
                                  "MM DD YYYY h:mm a"
                                ).format("MMMM DD YYYY h:mm a")}
                              </p>
                              <p>{detailReq.hours} hours</p>
                            </div>
                          )}
                          <p>Total: ${detailReq.total}</p>
                        </div>
                      </div>
                      {/* <StripeProvider
                            publishableKey={STRIPE_KEY}
                            merchantIdentifier="merchant.identifier">
                            <PaymentScreen
                              detailReq={detailReq}
                              onDone={() => {
                                this.fetchData();
                              }}
                              token={STRIPE_KEY}
                            />
                          </StripeProvider> */}
                    </div>
                  </div>
                ) : (
                  <div>
                    {notifs.map((n: any) => {
                      return (
                        <div key={n._id}>
                          <div
                            style={{
                              flexDirection: "row",
                              marginTop: 5,
                            }}
                          >
                            <div>
                              <div>
                                <div
                                  style={{
                                    marginTop: 30,
                                  }}
                                >
                                  <div
                                    style={{
                                      flexDirection: "row",
                                    }}
                                  >
                                    <img
                                      style={{
                                        borderRadius: 10,
                                        height: 50,
                                        width: 50,
                                      }}
                                      src={n.rental.photos[0].url}
                                    />
                                    <div
                                      style={{
                                        width: 250,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <p>{n.rental.title}</p>
                                      {n.days.length > 0 && (
                                        <div>
                                          {n.days.map((d: any) => {
                                            return (
                                              <p key={d}>
                                                {moment(d, "MM DD YYYY").format(
                                                  "MMMM DD YYYY"
                                                )}
                                              </p>
                                            );
                                          })}
                                        </div>
                                      )}
                                      {n.hours && (
                                        <div>
                                          <p>
                                            {moment(
                                              n.checkinDate,
                                              "MM DD YYYY h:mm a"
                                            ).format("MMMM DD YYYY h:mm a")}
                                          </p>
                                          <p>{n.hours} hours</p>
                                        </div>
                                      )}
                                      <p>Total: ${n.total}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              marginTop: 10,
                              flexDirection: "row",
                            }}
                          >
                            {userId === n.buyerId && (
                              <div>
                                {n.accepted ? (
                                  <div>
                                    <p>Booking accepted</p>
                                    <Button
                                      style={{
                                        marginTop: 10,
                                        padding: 10,
                                        width: 150,
                                        borderRadius: 10,
                                        backgroundColor: "black",
                                      }}
                                      onClick={() => {
                                        setDetailReq({ ...n });
                                      }}
                                    >
                                      Book Now
                                    </Button>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        padding: 10,
                                        width: 150,
                                        borderRadius: 10,
                                        backgroundColor: "red",
                                      }}
                                      onClick={() => {
                                        axios
                                          .post("/removeReqs", {
                                            id: n._id,
                                          })
                                          .then(resp => {
                                            alert("Request Cancelled");
                                            window.location.reload();
                                          });
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              marginTop: 10,
                              flexDirection: "row",
                            }}
                          >
                            {userId === n.sellerId && (
                              <div>
                                {n.accepted ? (
                                  <div>
                                    <p>Booking accepted</p>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      flexDirection: "row",
                                    }}
                                  >
                                    {["Accept", "Deny"].map((b, i) => {
                                      return (
                                        <Button
                                          key={b}
                                          style={{
                                            padding: 10,
                                            width: 150,
                                            borderRadius: 10,
                                            marginLeft: i === 0 ? 0 : 10,
                                            backgroundColor:
                                              i === 0 ? "green" : "red",
                                          }}
                                          onClick={() => {
                                            if (i === 0) {
                                              axios
                                                .post("/approveReqs", {
                                                  id: n._id,
                                                })
                                                .then(resp => {
                                                  alert("Request Denied");
                                                  window.location.reload();
                                                });
                                            } else {
                                              axios
                                                .post("/removeReqs", {
                                                  id: n._id,
                                                })
                                                .then(resp => {
                                                  alert("Request Denied");
                                                  window.location.reload();
                                                });
                                            }
                                          }}
                                        >
                                          {b}
                                        </Button>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  height: 350,
                }}
              >
                {threads.map((t: any, i: any) => {
                  return (
                    <div
                      key={i}
                      style={{
                        marginTop: 15,
                      }}
                    >
                      <Button
                        style={{
                          flexDirection: "row",
                        }}
                        onClick={() => {
                          getMessages(t.rec._id, t.sender._id);
                        }}
                      >
                        <img
                          style={{
                            display: "inline-block",
                            borderRadius: 15,
                            height: 30,
                            width: 30,
                          }}
                          src={
                            userId === t.sender._id
                              ? t.rec.image
                              : t.sender.image
                          }
                        />
                        <p
                          style={{
                            display: "inline-block",
                            marginTop: 5,
                            marginLeft: 10,
                          }}
                        >
                          {t.messages[t.messages.length - 1].text}
                        </p>
                      </Button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Messages;
