import React, { useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { Button } from "reactstrap";

interface IProps {
  makePayment: any;
  stripe: any;
  amount: any;
  elements: any;
}

const CheckoutForm = (props: IProps) => {
  return (
    <div>
      <div
        style={{
          marginTop: 15,
          marginBottom: 15,
          maxWidth: "100%",
        }}
      >
        <CardElement />
      </div>

      <div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          {props.makePayment.paying ? (
            <Button
              disabled={props.makePayment.paying}
              onClick={async () => {}}
            >
              Processing payment...
            </Button>
          ) : (
            <Button
              disabled={props.makePayment.paying}
              onClick={async () => {
                if (props.makePayment.email) {
                  const { error, paymentMethod } =
                    await props.stripe.createPaymentMethod({
                      type: "card",
                      card: props.elements.getElement(CardElement),
                    });
                  if (!error) {
                    props.makePayment.makePayment(
                      paymentMethod.id,
                      props.makePayment.amount
                    );
                  } else {
                    alert(error);
                  }
                } else {
                  alert("Enter an email");
                }
              }}
            >
              Pay
            </Button>
          )}
        </div>
      </div>

      <img
        style={{
          width: 150,
          marginTop: 20,
        }}
        src="/images/stripe.svg"
      />
    </div>
  );
};

export default CheckoutForm;
