import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "reactstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import q from "q";
import axios from "axios";
import upload from "superagent";

interface Iprops {
  userId: any;
}

function Create(props: Iprops) {
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState<any>(null);
  const [address, setAddress] = useState<any>(null);
  const [location, setLocation] = useState<any>(null);
  const [rental, setRental] = useState<any>({});
  const [percent, setPercent] = useState<any>(null);

  useEffect(() => {
    //
  }, []);

  const handleChange = (address: any) => {
    setAddress(address);
  };
  const handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then(results => {
        if (results[0]) {
          setLocation({
            type: "Point",
            coordinates: [
              results[0].geometry.location.lng(),
              results[0].geometry.location.lat(),
            ],
          });
          setAddress(results[0].formatted_address);
        }
      })
      .then(latLng => {})
      .catch(error => console.error("Error", error));
  };

  const createListing = () => {
    var _rental = rental;
    uploadPromise(file).then((url: any) => {
      _rental.photos = [{ url }];
      _rental.address = address;
      _rental.userId = props.userId;
      _rental.location = location;
      axios
        .post("/createRental", {
          userId: props.userId,
          rental: _rental,
        })
        .then((resp: any) => {
          /// update
          if (resp.data.status) {
            alert("rental created");
            window.location.reload();
          } else {
            alert(resp.data.message);
          }
        });
    });
  };

  const uploadPromise = (file: any) => {
    const deffered = q.defer();
    upload
      .post("/upload")
      .attach("theseNamesMustMatch", file)
      .on("progress", function (e: any) {
        //console.log(e.direction, "is done", e.percent, "%");
        setPercent(parseInt(e.percent));
      })
      .end(async (err, res) => {
        console.log(res.body.location);
        deffered.resolve(res.body.location);
      });
    return deffered.promise;
  };

  return (
    <div>
      <h1>Create listing</h1>
      <div>
        {preview && preview !== "" ? (
          <img
            style={{
              objectFit: "fill",
              height: 200,
              width: 200,
              borderStyle: "solid",
              borderWidth: 2,
              padding: 5,
              borderRadius: 10,
            }}
            src={preview}
          />
        ) : (
          <img
            style={{
              height: 200,
              width: 200,
              borderStyle: "dashed",
              borderWidth: 2,
              borderColor: "grey",
              padding: 5,
              marginBottom: 20,
              borderRadius: 10,
            }}
            src={
              "/images/placeholder.png"
            }
          />
        )}
        <div>
          <input
            type="file"
            accept="image/*"
            name="preview-file"
            id="preview-file"
            className="inputfile"
            onChange={(e: any) => {
              const preview: any = URL.createObjectURL(e.target.files[0]);
              console.log(preview);
              setFile(e.target.files[0]);
              setPreview(preview);
            }}
          />

          <label
            style={{
              height: 40,
              borderColor: "transparent",
              borderRadius: 3,
              cursor: "pointer",
              padding: 9,
              width: 250,
              marginTop: 20,
              backgroundColor: "black",
              color: "white",
            }}
            htmlFor="preview-file"
          >
            {file ? file.name : "Choose an image"}
          </label>
        </div>
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                style={{
                  paddingLeft: 10,
                  fontSize: 12,
                  borderRadius: 5,
                  height: 50,
                  width: 350,
                }}
                {...getInputProps({
                  placeholder: "Search location?",
                  className: "location-search-input",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <input
        placeholder="Title"
        style={{
          paddingLeft: 10,
          marginTop: 10,
          borderRadius: 5,
          height: 50,
          width: 350,
        }}
        onChange={(e: any) => {
          var _rental = rental;
          _rental.title = e.target.value;
          setRental({ ..._rental });
        }}
      />
      <br />
      <textarea
        placeholder="Description"
        style={{
          paddingLeft: 10,
          marginTop: 10,
          borderRadius: 5,
          height: 150,
          width: 350,
        }}
        onChange={(e: any) => {
          var _rental = rental;
          _rental.desc = e.target.value;
          setRental({ ..._rental });
        }}
      ></textarea>
      {["day"].map(h => {
        return (
          <div
            style={{
              marginTop: 10,
              flexDirection: "row",
            }}
          >
            <p
              style={{
                fontSize: 16,
                display: "inline-block",
                marginTop: 10,
              }}
            >
              {h.toLocaleUpperCase()}
            </p>
            <input
              type="number"
              placeholder={h + " price"}
              style={{
                borderWidth: 1,
                height: 40,
                marginLeft: 10,
                marginTop: 0,
                paddingLeft: 10,
                borderColor: "black",
                borderRadius: 5,
                backgroundColor: "white",
                width: 120,
              }}
              onChange={(e: any) => {
                var _rental = rental;
                _rental[h + "Price"] = e.target.value;
                setRental({ ..._rental });
              }}
            />
          </div>
        );
      })}
      <Button
        onClick={() => {
          createListing();
        }}
      >
        Submit
      </Button>
    </div>
  );
}

export default Create;
