import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import upload from "superagent";
import q from "q";
import Header from "../comps/header";
import PricingComp from "../comps/pricingComp";
import Select from "react-select";
import { setuid } from "process";
import { Button, Container } from "reactstrap";

interface IProps {
  userId: any;
}

const Billing = (props: IProps) => {
  const [bills, setBills] = useState([]);
  const [user, setUser] = useState<any>(null);
  const [selected, setSelected] = useState({ label: "Year", value: "year" });
  const [plan, setPlan] = useState(null);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState<any>(null);
  const [index, setIndex] = useState(1);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .post("/checkSub", {
        userID: localStorage.getItem("@silla_id"),
      })
      .then(resp => {
        console.log(resp.data);
        setMessage(resp.data.message);
        setStatus(resp.data.status);
        setPlan(resp.data.plan);
      });
  };
  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          padding: 20,
          minHeight: 1200,
        }}
      >
        <div
          style={{
            width: "100%",
            paddingTop: 20,
            backgroundColor: "white",
            textAlign: "center",
          }}
        >
          <div>
            <PricingComp
              message={message}
              status={status}
              small={props.small}
              plan={plan}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
