import React, { useEffect, useState } from "react";
import axios from "axios";
import { Dock } from "react-dock";
import { FiMenu, FiXSquare } from "react-icons/fi";
interface Iprops {
  small: boolean;
  onOpen: any;
  detail: any;
}

const Header = (props: Iprops) => {
  return (
    <div
      style={{
        width: "100%",
        paddingLeft: 10,
        display: "inline-block",
        paddingTop: 5,
        backgroundColor: "white",
        height: 60,
      }}
    >
      <div
        style={{
          width: "80%",
          display: "inline-block",
        }}
      >
        <a
          href="/"
          style={{
            display: "inline-block",
          }}
        >
          <img
            style={{ width: props.small ? 120 : 200 }}
            src="/images/logo.svg"
          />
        </a>
      </div>
      {props.small && !props.detail && (
        <div
          style={{
            width: 30,
            display: "inline-block",
            height: 30,
            marginLeft: 30,
            zIndex: 80000,
            textAlign: "center",
            marginTop: 15,
            backgroundColor: "white",
            borderRadius: 5,
            //position: "absolute",
          }}
          onClick={() => {
            props.onOpen(true);
          }}
        >
          <FiMenu
            size={26}
            onClick={() => {
              props.onOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
