import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import OnBoardSuccess from "./onboard-success";
import Forgot from "./forgot";
import Detail from "./detail";
import Booking from "./booking";
import Dash from "./dash";
import { Button, Col, Row } from "reactstrap";
import Header from "./comps/header";
import Success from "./comps/success";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
console.warn = () => {};

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
};

function App() {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const small = dimensions.width > 1100 ? false : true;
  return (
    <div>
      <Provider template={AlertTemplate} {...options}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Dash small={small} />
            </Route>
            <Route exact path="/success">
              <Success />
            </Route>
            <Route exact path="/listing/:id">
              <Detail small={small} />
            </Route>

            <Route exact path="/onboard-success">
              <OnBoardSuccess small={small} />
            </Route>
            <Route path="/reset-password/:id">
              <Forgot
                small={true}
                showAlert={(text: string, success: boolean) => {
                  alert(text);
                }}
              />
            </Route>
          </Switch>
        </Router>

        <div
          style={{
            backgroundColor: "#F5F5F5",
            paddingTop: 20,
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 30,
          }}
        >
          <Row>
            <Col>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <h3 style={{ color: "black" }}>Policy</h3>
                  <a
                    href="https://opensilla.com/terms"
                    target="_blank"
                    style={{ color: "black" }}
                  >
                    <p>Terms of Use</p>
                  </a>
                  <a
                    href="https://opensilla.com/privacy"
                    target="_blank"
                    style={{ color: "black" }}
                  >
                    <p>Privacy Policy</p>
                  </a>
                  <a
                    href="https://opensilla.com/cookie"
                    target="_blank"
                    style={{ color: "black" }}
                  >
                    <p>Cookie Policy</p>
                  </a>
                  <Row>
                    <Col xl="6">
                      <a
                        href="https://opensilla.com"
                        target="_blank"
                        style={{ color: "black" }}
                      >
                        <img
                          style={{
                            marginLeft: -8,
                            marginTop: -8,
                            width: 120,
                          }}
                          src="/images/google.svg"
                        />
                      </a>
                    </Col>
                    <Col xl="4">
                      <a
                        href="https://opensilla.com"
                        target="_blank"
                        style={{ color: "black" }}
                      >
                        <img
                          style={{
                            width: 100,
                          }}
                          src="/images/apple.svg"
                        />
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <h3 style={{ color: "black" }}>Contact Us</h3>
                  <Button
                    onClick={() => {
                      window.open("https://opensilla.com", "_blank");
                    }}
                  >
                    contact
                  </Button>
                </div>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <div>
                  <h3 style={{ color: "black" }}>Resources</h3>
                  <a
                    href="https://opensilla.com"
                    target="_blank"
                    style={{ color: "black" }}
                  >
                    <p>Help Center</p>
                  </a>
                </div>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  textAlign: "left",
                }}
              ></div>
            </Col>
          </Row>

          <Row>
            <Col>
              <img style={{ width: 100 }} src="/images/logo.svg" />
            </Col>
            <Col>
              <p style={{ color: "black", textAlign: "right" }}>
                © 2022 Open Silla
              </p>
            </Col>
          </Row>
        </div>
      </Provider>
    </div>
  );
}

export default App;
