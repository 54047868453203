import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

interface IProps {
  small: boolean;
  showAlert: any;
}

const Forgot = (props: IProps) => {
  useEffect(() => {
    fetchData();
  }, []);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordTwo, setPasswordTwo] = useState<any>(null);

  const fetchData = () => {
    const token = window.location.pathname.replace("/reset-password/", "");
    axios
      .post("/checkResetLink", {
        token,
      })
      .then(resp => {
        if (resp.data.status) {
          setLoading(false);
          setValid(true);
        } else {
          setLoading(false);
          setValid(false);
        }
      });
  };
  const submit = () => {
    const token = window.location.pathname.replace("/reset-password/", "");
    if (password === passwordTwo) {
      axios
        .post("/resetPassword", {
          password,
          token,
        })
        .then(resp => {
          if (resp.data.status) {
            alert("password updated");
            window.location.href = "/";
          } else {
            alert(resp.data.message);
          }
        });
    } else {
      alert("Passwords must match");
    }
  };

  return (
    <div>
      <div
        style={{
          height: 1200,
          color: "black",
          width: "100%",
          backgroundColor: "white",
          paddingTop: 70,
          textAlign: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 20,
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            transition: "0.3s",
            padding: 20,
            width: "90%",
            maxWidth: 400,
            margin: "auto",
          }}
        >
          <h3>Reset Password</h3>
          {loading ? (
            <div>loading...</div>
          ) : (
            <div>
              {valid ? (
                <div>
                  <input
                    type="password"
                    style={{
                      height: 30,
                      padding: 5,
                      borderRadius: 5,
                      width: 300,
                    }}
                    placeholder="New Password"
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                  />
                  <br />
                  <input
                    type="password"
                    style={{
                      height: 30,
                      marginTop: 20,
                      padding: 5,
                      borderRadius: 5,
                      width: 300,
                    }}
                    placeholder="Confirm Password"
                    onChange={e => {
                      setPasswordTwo(e.target.value);
                    }}
                  />
                  <br />
                  <button
                    style={{
                      width: 150,
                      cursor: "pointer",
                      borderRadius: 5,
                      backgroundColor: "black",
                      color: "white",
                      marginTop: 10,
                    }}
                    onClick={() => {
                      submit();
                    }}
                  >
                    Submit
                  </button>
                </div>
              ) : (
                <div>
                  <h3>This link has expired</h3>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Forgot;
