import React, { useEffect, useState } from "react";
import { Dock } from "react-dock";
import Header from "./comps/header";
import { Card, Container, Button, Row, Col } from "reactstrap";
import { FiMenu, FiXSquare } from "react-icons/fi";
import Search from "./comps/search";
import Favs from "./comps/favs";
import Profile from "./comps/profile";
import Messages from "./comps/messages";
import Create from "./comps/create";
import Listings from "./comps/listings";
import Bookings from "./comps/booking";
import Login from "./comps/login";
import Billing from "./comps/billing";
import Payments from "./comps/payments";

interface Iprops {
  small: boolean;
}

function Dash(props: Iprops) {
  const [content, setContent] = useState<any>(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const l: any = window.localStorage.getItem("@silla_id");
    if (l) {
      setLoggedIn(true);
      setUserId(l);
      //setContent(<Messages userId={l} />);
    }
  }, []);
  const BUTTONS = [
    { label: "Login", comp: <Login />, login: false },
    { label: "Search", comp: null, login: false },
    { label: "Help Center", link: "https://opensilla.com" },
  ];

  const LOGGEDIN_BUTTONS = [
    { label: "Search", comp: null, login: false },
    { label: "Favorites", comp: <Favs userId={userId} />, login: true },
    { label: "My Listings", comp: <Listings userId={userId} />, login: true },
    { label: "Inbox", comp: <Messages userId={userId} />, login: true },
    { label: "Create Listing", comp: <Create userId={userId} />, login: true },
    { label: "Bookings", comp: <Bookings userId={userId} />, login: true },
    { label: "Profile", comp: <Profile userId={userId} />, login: true },
    { label: "Billing", comp: <Billing userId={userId} />, login: true },
    { label: "Payments", comp: <Payments userId={userId} />, login: true },
    { label: "Help Center", link: "https://opensilla.com" },
  ];
  return (
    <div
      style={{
        minHeight: 1000,
        backgroundColor: "white",
      }}
    >
      <Header
        detail={false}
        onOpen={(isOpen: any) => {
          setIsOpen(isOpen);
        }}
        small={props.small}
      />

      <Row
        style={{
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        {!props.small && (
          <Col
            xs="2"
            sm="2"
            md="2"
            lg="2"
            xl="2"
            style={{
              minHeight: 1000,
              paddingLeft: 30,
              backgroundColor: "white",
            }}
          >
            {loggedIn ? (
              <div>
                {LOGGEDIN_BUTTONS.map((b: any) => {
                  return (
                    <Button
                      style={{
                        textAlign: "left",
                        width: "90%",
                      }}
                      key={b.label}
                      onClick={() => {
                        if (b.link) {
                          window.open(b.link, "_blank");
                        } else {
                          setContent(b.comp);
                        }
                      }}
                    >
                      {b.label}
                    </Button>
                  );
                })}
              </div>
            ) : (
              <div>
                {BUTTONS.map((b: any) => {
                  return (
                    <Button
                      style={{
                        textAlign: "left",
                        width: "90%",
                      }}
                      key={b.label}
                      onClick={() => {
                        if (b.link) {
                          window.open(b.link, "_blank");
                        } else {
                          setContent(b.comp);
                        }
                      }}
                    >
                      {b.label}
                    </Button>
                  );
                })}
              </div>
            )}
          </Col>
        )}
        <Col
          style={{
            padding: 0,
          }}
        >
          {content ? (
            <div
              style={{
                color: "black",
                padding: 20,
              }}
            >
              {content}
            </div>
          ) : (
            <Search />
          )}
        </Col>
      </Row>
      <Dock isVisible={isOpen} position={"left"} size={0.5}>
        <div>
          <FiXSquare
            style={{
              marginTop: 10,
              marginLeft: 10,
            }}
            size={26}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <div>
          {loggedIn ? (
            <div>
              {LOGGEDIN_BUTTONS.map((b: any) => {
                return (
                  <Button
                    style={{
                      textAlign: "left",
                      width: "90%",
                    }}
                    key={b.label}
                    onClick={() => {
                      setIsOpen(false);
                      if (b.link) {
                        window.open(b.link, "_blank");
                      } else {
                        setContent(b.comp);
                      }
                    }}
                  >
                    {b.label}
                  </Button>
                );
              })}
            </div>
          ) : (
            <div>
              {BUTTONS.map((b: any) => {
                return (
                  <Button
                    style={{
                      textAlign: "left",
                      width: "90%",
                    }}
                    key={b.label}
                    onClick={() => {
                      setIsOpen(false);
                      if (b.link) {
                        window.open(b.link, "_blank");
                      } else {
                        setContent(b.comp);
                      }
                    }}
                  >
                    {b.label}
                  </Button>
                );
              })}
            </div>
          )}
        </div>
      </Dock>
    </div>
  );
}

export default Dash;
