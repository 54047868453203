import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "reactstrap";
import axios from "axios";

interface Iprops {
  onPressed: any;
  data: any;
}

function RentalCard(props: Iprops) {
  const rental = props.data;
  return (
    <Card
      style={{
        display: "inline-block",
        cursor: "pointer",
        width: 250,
        textAlign: "center",
        padding: 20,
      }}
      onClick={() => {
        props.onPressed(rental);
      }}
    >
      {rental && (
        <div>
          <img
            style={{
              maxWidth: "90%",
              borderRadius: 5,
            }}
            src={
              rental && rental.photos && rental.photos[0]
                ? rental.photos[0].url
                : "/images/placeholder.png"
            }
          />
          <h1
            style={{
              color: "black",
            }}
          >
            {rental.title}
          </h1>
          <p>{rental.desc}</p>

          <div>
            {["day"].map((d, i) => {
              console.log(rental);
              return (
                <div
                  key={d}
                  style={{
                    marginLeft: 0,
                    cursor: "pointer",
                    backgroundColor: "#69F0AE",
                    height: 50,
                    marginTop: 10,
                    paddingTop: 10,
                    fontWeight: "bold",
                    fontSize: 18,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    ${rental[d + "Price"]} / {d}
                  </p>
                </div>
              );
            })}
          </div>

          <h3>{rental.address}</h3>
        </div>
      )}
    </Card>
  );
}

export default RentalCard;
