import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Button } from "reactstrap";

const App = () => {
  useEffect(() => {
    fetchData();
  }, []);
  const [plan, setPlan] = useState({
    email: "",
    start: "",
    end: "",
    amount: 0,
  });
  const fetchData = () => {
    const session_id = window.location.search.replace("?session_id=", "");
    console.log("fetch session " + session_id);
    axios
      .post("/checkPayment", {
        session_id,
        userID: localStorage.getItem("@silla_id"),
      })
      .then(resp => {
        if (resp.data.status) {
          console.log("payment made");
          console.log(resp.data);
          setPlan(resp.data.plan);
        } else {
          console.log("payment not made");
        }
      });
  };

  return (
    <div>
      <div
        style={{
          height: 1200,
          width: "100%",
          paddingTop: 20,
          backgroundColor: "white",
          textAlign: "center",
        }}
      >
        <Card
          style={{
            borderRadius: 10,
            minHeight: 450,
            padding: 10,
            textAlign: "center",
            margin: "auto",
            width: "70%",
          }}
        >
          <p>Thank you for your payment </p>
          <p>Email : {plan && plan.email}</p>
          <p>Started : {plan && plan.start}</p>
          <p>Next Billing date : {plan && plan.end}</p>
          <p>Cost : {plan && "$" + plan.amount}</p>

          <Button
            style={{
              borderRadius: 5,

              padding: 5,
              marginTop: 10,
              cursor: "pointer",
              color: "black",
              fontSize: 16,
              borderColor: "transparent",
            }}
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Get Started
          </Button>
        </Card>
      </div>
    </div>
  );
};

export default App;
