import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import { loadStripe } from "@stripe/stripe-js";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Row,
  Col,
  Button,
} from "reactstrap";
import { FaCheckCircle } from "react-icons/fa";
import Select from "react-select";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import config from "../config";
const stripePromise = loadStripe(config.stripe);

export default class PricingComp extends Component {
  constructor() {
    super();
    this.state = {
      seats: { value: 1, label: "1" },
      active: true,
    };
  }

  componentDidMount() {}
  render() {
    const { user, seats, active } = this.state;
    const options = _.map(new Array(26), function (el, i) {
      return { value: i, label: i.toString() };
    });
    const PRICES = [
      {
        title: "Started",
        price: "49.95",
        services: ["Unlimited Listings (1 location)"],
        plan: "price_1KfBlAA0mSayxLehjc5SZP3q", // live
        //plan: "price_1K8cqfG8DAC6kOp23vpCiJkN",
      },
      {
        title: "Premium",
        price: "149.95",
        services: ["Unlimited Listings (mulitple locations)"],
        plan: "price_1L5FMOA0mSayxLehfCqHiP3u", // live
        //plan: "price_1K8cqfG8DAC6kOp23vpCiJkN",
      },
    ];
    return (
      <div
        style={{
          fontFamily: "Montserrat",
          backgroundColor: "white",
          minHeight: 800,
        }}
      >
        <div
          style={{
            padding: 10,
          }}
        >
          <div>
            <h3
              style={{
                fontWeight: 700,
              }}
            >
              Current Plan :{" "}
              {this.props.plan
                ? `$${this.props.plan.amount} month`
                : this.props.status
                ? "Free"
                : this.props.message}
            </h3>
            <h4
              style={{
                fontWeight: 700,
              }}
            >
              {this.props.plan
                ? `Next billing date : ${this.props.plan.end}`
                : null}
            </h4>

            <br />
            {/* <div>
              <label
                style={{ marginRight: 20, verticalAlign: "super" }}
                htmlFor="cheese-status"
              >
                Billed Monthly
              </label>
              <Toggle
                id="cheese-status"
                checked={active || false}
                onChange={e => {
                  this.setState({ active: e.target.checked });
                }}
              />
              <label
                style={{ marginLeft: 20, verticalAlign: "super" }}
                htmlFor="cheese-status"
              >
                Billed Annualy -
                <span style={{ color: config.purple, fontWeight: "bold" }}>
                  Save 30%
                </span>
              </label>
            </div> */}
            <br />
            {this.props.plan && (
              <Button
                style={{
                  color: "white",
                  cursor: "pointer",
                  backgroundColor: "red",
                }}
                onClick={() => {
                  var c = window.confirm("Cancel this subscription?");
                  if (c) {
                    axios
                      .post("/cancelSub", {
                        userID: localStorage.getItem("@silla_id"),
                      })
                      .then(async resp => {
                        window.location.reload();
                      });
                  }
                }}
              >
                Cancel Subscription
              </Button>
            )}

            {PRICES.map((p, i) => {
              return (
                <Card
                  style={{
                    display: "inline-block",
                    width: "100%",
                    borderRadius: 20,
                    marginTop: 20,
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    transition: "0.3s",
                    color: "black",
                    backgroundColor: "white",
                  }}
                >
                  <CardBody>
                    <CardTitle
                      style={{
                        fontFamily: "Montserrat",
                        textAlign: "left",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: 36,
                      }}
                      tag="h5"
                    >
                      <span
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: 18,
                        }}
                      >
                        {" "}
                        {p.title}
                      </span>

                      <br />
                      <span
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 800,
                          fontSize: 35,
                          color: "black",
                        }}
                      >
                        ${p.price}
                      </span>
                      <span style={{ fontSize: 14, marginLeft: 10 }}>
                        / per month
                      </span>
                    </CardTitle>

                    {p.services.map(r => {
                      return (
                        <div
                          key={r}
                          style={{
                            marginTop: 25,
                            fontFamily: "Montserrat",
                            fontSize: 18,
                            fontWeight: 400,
                            textAlign: "left",
                          }}
                        >
                          <FaCheckCircle
                            style={{
                              fontSize: 28,
                              marginRight: 10,
                              color: "#A5D6A7",
                            }}
                          />{" "}
                          {r}
                        </div>
                      );
                    })}
                    <Button
                      role="link"
                      style={{
                        padding: 0,
                        width: "90%",
                        fontFamily: "Montserrat",
                        fontWeight: 800,
                        color: "white",
                        fontSize: 20,
                        height: 50,
                        borderRadius: 10,
                        backgroundColor: "black",
                      }}
                      onClick={async () => {
                        const stripe = await stripePromise;
                        axios
                          .post("/create-checkout-session", {
                            paymentID: p.plan,
                            cancelUrl: window.location.href,
                            userID: localStorage.getItem("@silla_id"),
                          })
                          .then(async resp => {
                            const id = resp.data.id;
                            const result = await stripe.redirectToCheckout({
                              sessionId: id,
                            });

                            if (result.error) {
                              console.log(result.error);
                            }
                          });
                      }}
                    >
                      Get started
                    </Button>
                  </CardBody>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
