import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "reactstrap";
import axios from "axios";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Modal from "react-modal";
import { FiMapPin } from "react-icons/fi";
import { useAlert } from "react-alert";
const styled = require("styled-components");

const Styles = styled.default.div`
  .silla-box {
    padding:20px;
  }
  `;

Modal.setAppElement("#root");

const customStyles = {
  overlay: {
    zIndex: 9000000000,
    marginTop: 50,
    backgroundColor: "transparent",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    //maxWidth: "90%",
    height: 250,
    width: "95%",
    marginTop: 20,
  },
};

function Search() {
  const alert = useAlert();
  const [map, setMap] = useState<any>(null);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(true);

  const [isOpen, setIsOpen] = useState<any>(null);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [location, setLocation] = useState<any>(null);
  const [address, setAddress] = useState<any>("");
  const [zoom, setZoom] = useState(10);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC3TKVdufylu3i69Cn0tzPjqJx16hw709E",
  });

  const my_svgMarker = {
    path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillColor: "blue",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new google.maps.Point(15, 30),
  };

  const svgMarker = {
    path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillColor: "#69F0AE",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new google.maps.Point(15, 30),
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const getLocation = () => {
    setLoadingLocation(true);
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        console.log(coords);
        setLocation({
          lat: coords.latitude,
          long: coords.longitude,
        });
        getRentals(coords.latitude, coords.longitude);
        setLoadingLocation(false);
      },
      (err: any) => {
        console.log(err);

        alert.error(err.message);
        setLoadingLocation(false);
        setLocation({
          lat: 30.3322,
          long: -81.6557,
        });
        getRentals(30.3322, -81.6557);
      }
    );
  };

  const getRentals = (currentLatitude: any, currentLongitude: any) => {
    setLoading(true);
    axios
      .post("/getRentalByLoc", {
        currentLatitude,
        currentLongitude,
      })
      .then(resp => {
        console.log(resp.data);
        setListings(resp.data.rentals);
        setZoom(15);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchData = () => {};

  const handleChange = (address: any) => {
    setAddress(address);
  };
  const handleSelect = (address: any) => {
    geocodeByAddress(address)
      .then(results => {
        if (results[0]) {
          setLocation({
            lat: results[0].geometry.location.lat(),
            long: results[0].geometry.location.lng(),
          });
          setAddress(results[0].formatted_address);
          getRentals(
            results[0].geometry.location.lat(),
            results[0].geometry.location.lng()
          );
        }
      })
      .then(latLng => {})
      .catch(error => console.error("Error", error));
  };

  useEffect(() => {
    // get user location
    getLocation();
  }, []);
  return (
    <div
      style={{
        marginTop: 5,
        padding: 0,
        backgroundColor: "transparent",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 900000,
          marginTop: 400,
          marginLeft: "65vw",
          backgroundColor: "black",
          borderRadius: 25,
          paddingTop: 10,
          textAlign: "center",
          height: 50,
          width: 50,
        }}
        onClick={() => {
          getLocation();
        }}
      >
        <FiMapPin color="white" size={25} />
      </div>
      {loadingLocation && (
        <div
          className="webkit"
          style={{
            marginTop: 100,
            width: "100%",
            zIndex: 800000,
            position: "absolute",
          }}
        >
          <div
            style={{
              backgroundColor: "black",
              borderRadius: 10,
              padding: 10,
              paddingTop: 35,
              height: 100,
              width: 150,
            }}
          >
            <h4
              style={{
                color: "white",
                fontWeight: "bold",
              }}
            >
              Getting location...
            </h4>
          </div>
        </div>
      )}
      {loading && (
        <div
          className="webkit"
          style={{
            marginTop: 100,
            width: "100%",
            zIndex: 800000,
            position: "absolute",
          }}
        >
          <div
            style={{
              backgroundColor: "black",
              borderRadius: 10,
              padding: 10,
              paddingTop: 35,
              height: 100,
              width: 150,
            }}
          >
            <h4
              style={{
                color: "white",
                fontWeight: "bold",
              }}
            >
              Loading rentals...
            </h4>
          </div>
        </div>
      )}
      <div>
        <div>
          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div
                style={{
                  width: "100%",
                  marginTop: 10,
                  zIndex: 100,
                  textAlign: "center",
                  position: "absolute",
                }}
              >
                <input
                  style={{
                    paddingLeft: 10,
                    fontSize: 12,
                    borderRadius: 10,
                    height: 40,
                    width: 250,
                  }}
                  {...getInputProps({
                    placeholder: "Where are you working?",
                    className: "location-search-input",
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>

          <div className="webkit">
            {isLoaded && location && location.lat ? (
              <GoogleMap
                options={{
                  disableDefaultUI: true,
                }}
                onClick={(e: any) => {
                  console.log("clicked on map");
                  // const lat = e.latLng.lat();
                  // const long = e.latLng.lng();
                  // setLocation({
                  //   lat,
                  //   long,
                  // });
                  // getRentals(lat, long);
                }}
                mapContainerStyle={{
                  width: "100%",
                  height: "100vh",
                }}
                center={{
                  lat: location.lat,
                  lng: location.long,
                }}
                zoom={zoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                <Marker
                  icon={my_svgMarker}
                  position={{
                    lat: location.lat,
                    lng: location.long,
                  }}
                ></Marker>
                {listings.map((list: any) => {
                  return (
                    <Marker
                      animation={window.google.maps.Animation.DROP}
                      key={list.rental._id}
                      onClick={() => {
                        setIsOpen(list.rental._id);
                        //window.open(`/listing/${list.rental._id}`, "_blank");
                      }}
                      icon={svgMarker}
                      position={{
                        lat: list.rental.location.coordinates[1],
                        lng: list.rental.location.coordinates[0],
                      }}
                    >
                      {isOpen === list.rental._id && (
                        <InfoWindow
                          onCloseClick={() => {
                            console.log("on close");
                            setIsOpen(null);
                          }}
                        >
                          <div>
                            <a
                              href={`/listing/${list.rental._id}`}
                              target="_blank"
                            >
                              <h3>{list.rental.title}</h3>
                              <br />
                              <img
                                style={{
                                  width: 150,
                                  borderRadius: 5,
                                }}
                                src={
                                  list &&
                                  list.rental &&
                                  list.rental.photos &&
                                  list.rental.photos[0]
                                    ? list.rental.photos[0].url
                                    : "/images/placeholder.png"
                                }
                              />
                              <p>
                                {list.rental.dayPrice
                                  ? "$" + list.rental.dayPrice + "/Day"
                                  : "Price not available"}
                              </p>
                              <Button>View</Button>
                            </a>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  );
                })}
              </GoogleMap>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            marginLeft: 0,
            maxWidth: "95%",
          }}
        >
          <h4>Search for salon space to rent or list your own</h4>
          <button
            style={{
              width: "100%",
            }}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Get Started
          </button>
          <br />
        </div>
      </Modal>
    </div>
  );
}

export default Search;
