import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "reactstrap";
import axios from "axios";
import moment from "moment";
interface Iprops {
  userId: any;
}

function Payments(props: Iprops) {
  const [type, setType] = useState("Payments");
  const [sales, setSales] = useState([]);
  const [account, setAccount] = useState<any>(null);
  const [balance, setBalance] = useState<any>(null);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    if (props.userId) {
      axios
        .post("/getSales", {
          userId: props.userId,
        })
        .then(resp => {
          setSales(resp.data);
        });

      axios
        .post("/getStripeData", {
          userId: props.userId,
        })
        .then(resp => {
          console.log(resp.data);
          if (resp.data.status) {
            setAccount(resp.data.data);
            setBalance(resp.data.balance);
          }
        });
    }
  };
  return (
    <div>
      <div>
        {["Payments", "Payouts"].map((m: any) => {
          return (
            <Button
              key={m}
              style={{
                backgroundColor: type === m ? "black" : "white",
                color: type === m ? "white" : "black",
                display: "inline-block",
              }}
              onClick={() => {
                setType(m);
              }}
            >
              {m}
            </Button>
          );
        })}
        <div>
          {type === "Payments" ? (
            <div>
              {sales.map((b: any) => {
                return (
                  <div key={b.booking._id}>
                    <div>
                      <Card
                        style={{
                          padding: 10,
                          width: 350,
                          marginTop: 30,
                        }}
                      >
                        <div
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <img
                            style={{
                              borderRadius: 10,
                              height: 50,
                              width: 50,
                            }}
                            src={b.booking.rental.photos[0].url}
                          />
                          <div
                            style={{
                              width: 250,
                              marginLeft: 20,
                            }}
                          >
                            <div>{b.booking.rental.title}</div>
                            {b.booking.days.length > 0 && (
                              <div>
                                {b.booking.days.map((d: any) => {
                                  return (
                                    <div>
                                      {moment(d, "MM DD YYYY").format(
                                        "MMMM DD YYYY"
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            {b.booking.hours && (
                              <div>
                                <div>
                                  {moment(
                                    b.booking.checkinDate,
                                    "MM DD YYYY h:mm a"
                                  ).format("MMMM DD YYYY h:mm a")}
                                </div>
                                <div>{b.booking.hours} hours</div>
                              </div>
                            )}
                            <div>Total: ${b.booking.total}</div>
                            {b.booking.receipt && (
                              <Button
                                onClick={() => {
                                  window.open(b.booking.receipt, "_blank");
                                }}
                              >
                                <div
                                  style={{
                                    color: "blue",
                                  }}
                                >
                                  Receipt
                                </div>
                              </Button>
                            )}
                            <Button
                              onClick={() => {
                                window.open(
                                  `/listing/${b.booking.rental._id}`,
                                  "_blank"
                                );
                              }}
                            >
                              View
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </div>
                    <div
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Button
                        style={{
                          flexDirection: "row",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: 15,
                            height: 30,
                            width: 30,
                          }}
                          src={b && b.user && b.user.image}
                        />
                        <div
                          style={{
                            marginLeft: 10,
                            marginTop: 5,
                          }}
                        >
                          {b && b.user && b.user.name}
                        </div>
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                //width: "70%",
                textAlign: "left",
                marginTop: 20,
              }}
            >
              <Button
                style={{
                  justifyContent: "left",
                  height: 50,
                  marginLeft: 10,
                  borderRadius: 10,
                  backgroundColor: "black",
                  marginTop: 20,
                }}
                onClick={async () => {
                  axios
                    .post("/getStripe", {
                      userId: props.userId,
                    })
                    .then(resp => {
                      if (resp.data.status) {
                        window.open(resp.data.url, "_blank");
                      }
                    });
                }}
              >
                <div
                  style={{
                    fontSize: 18,
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {account ? "Update Account Info" : "Connect Stripe"}
                </div>
              </Button>
              <br />
              <div
                style={{
                  marginTop: 10,
                  textAlign: "left",
                }}
              >
                <img
                  style={{
                    marginLeft: 10,
                    width: 100,
                  }}
                  src="./images/stripe.svg"
                />
              </div>
              {account && balance && (
                <div
                  style={{
                    marginTop: 10,
                    borderRadius: 10,
                    backgroundColor: "white",
                    height: 250,
                  }}
                >
                  <div
                    style={{
                      padding: 10,
                    }}
                  >
                    <div>Balance</div>
                    <div>
                      Available: $
                      {balance && balance.available[0].amount.toFixed(2)
                        ? balance.available[0].amount.toFixed(2)
                        : 0}
                    </div>
                    <div>
                      Pending: $
                      {balance && balance.pending[0].amount.toFixed(2)
                        ? balance.pending[0].amount.toFixed(2)
                        : 0}
                    </div>
                    <div>
                      {account && account.charges_enabled
                        ? "charges enabled"
                        : "charges not enabled"}
                    </div>
                    <div>
                      {account && account.payouts_enabled
                        ? "payouts enabled"
                        : "payouts not enabled"}
                    </div>
                    {account &&
                      account.external_accounts.data.map((a: any) => {
                        return (
                          <div style={{ padding: 10, marginTop: 5 }} key={a.id}>
                            <div>
                              <div>
                                {a.brand} {a.funding} : {a.last4} :{" "}
                                {a.exp_month}/{a.exp_year}
                              </div>
                            </div>
                            <div>
                              <div>Payouts Available</div>
                              {a.available_payout_methods.map((r: any) => {
                                return (
                                  <div key={r}>
                                    <div>{r}</div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Payments;
