const config = {
  stripe:
    "pk_live_51KQDf9A0mSayxLehoL3BUeChAVOOd8CgbBVXMQUWEHIPVh254CPnfOxpBX5m3QncdLDZzoCtrAwTaQOLnzseBPxx0026p6Q9RB", /// live
  black: "#263238",
  lightGrey: "#FAFAFA",
  mediumGrey: "#ECEFF1",
  darkRed: "#FF1744",
};

export default config;
