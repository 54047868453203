import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "reactstrap";
import axios from "axios";
import RentalCard from "./rentalCard";
import { useAlert } from "react-alert";
interface Iprops {
  userId: any;
}

function Listings(props: Iprops) {
  const alert = useAlert();
  const [rentals, setRentals] = useState([]);
  const [detail, setDetail] = useState<any>(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (props.userId) {
      axios.post("/getListings", { userId: props.userId }).then(resp => {
        console.log(resp.data);
        setRentals(resp.data);
      });
    } else {
    }
  };

  const removeListing = async (id: any) => {
    axios.post("/removeListing", { id, userId: props.userId }).then(resp => {
      if (resp.data.status) {
        alert.success("rental removed");
        window.location.reload();
      } else {
        alert.error(resp.data.message);
      }
    });
  };

  const updateListing = async () => {
    axios
      .post("/updateListingDetails", { rental: detail, userId: props.userId })
      .then(resp => {
        if (resp.data.status) {
          alert.success("rental updated");
          window.location.reload();
        } else {
          alert.error(resp.data.message);
        }
      });
  };

  return (
    <div>
      <h1>My Listings</h1>
      {detail ? (
        <div>
          <input
            placeholder="Title"
            style={{
              paddingLeft: 10,
              marginTop: 10,
              borderRadius: 5,
              height: 50,
              width: 350,
            }}
            value={detail.title}
            onChange={(e: any) => {
              var _detail = detail;
              _detail.title = e.target.value;
              setDetail({ ..._detail });
            }}
          />
          <br />
          <textarea
            value={detail.desc}
            placeholder="Description"
            style={{
              paddingLeft: 10,
              marginTop: 10,
              borderRadius: 5,
              height: 150,
              width: 350,
            }}
            onChange={(e: any) => {
              var _detail = detail;
              _detail.desc = e.target.value;
              setDetail({ ..._detail });
            }}
          ></textarea>
          {["day"].map(h => {
            return (
              <div
                style={{
                  marginTop: 10,
                  flexDirection: "row",
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    display: "inline-block",
                    marginTop: 10,
                  }}
                >
                  {h.toLocaleUpperCase()}
                </p>
                <input
                  type="number"
                  placeholder={h + " price"}
                  style={{
                    borderWidth: 1,
                    height: 40,
                    marginLeft: 10,
                    marginTop: 0,
                    paddingLeft: 10,
                    borderColor: "black",
                    borderRadius: 5,
                    backgroundColor: "white",
                    width: 120,
                  }}
                  value={detail[h + "Price"]}
                  onChange={(e: any) => {
                    var _detail = detail;
                    _detail[h + "Price"] = e.target.value;
                    setDetail({ ..._detail });
                  }}
                />
              </div>
            );
          })}
          <Button
            onClick={() => {
              updateListing();
            }}
          >
            Update
          </Button>
          <Button
            onClick={() => {
              setDetail(null);
            }}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <div>
          {rentals.map((r: any) => {
            return (
              <div style={{ display: "inline-block" }}>
                <RentalCard
                  onPressed={(rental: any) => {
                    window.open(`/listing/${rental._id}`, "_blank");
                  }}
                  key={r._id}
                  data={r}
                />
                <br />
                <Button
                  onClick={() => {
                    setDetail(r);
                  }}
                >
                  Edit
                </Button>
                <Button
                  style={{
                    backgroundColor: "red",
                    color: "white",
                  }}
                  onClick={() => {
                    var c = window.confirm("Remove this listing?");
                    if (c) {
                      removeListing(r._id);
                    }
                  }}
                >
                  Remove
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Listings;
