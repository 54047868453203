import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import upload from "superagent";
import q from "q";
import { Card, Container, Button } from "reactstrap";

interface IProps {
  userId: any;
}

const Profile = (props: IProps) => {
  const [file, setFile] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [preview, setPreview] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    axios
      .post("/getProfile", {
        userId: props.userId,
      })
      .then(resp => {
        console.log(resp.data);
        if (resp.data.status) {
          setUser(resp.data.user);
        }
      });
  };
  const uploadPromise = (file: any) => {
    const that = this;
    const deffered = q.defer();
    upload
      .post("/upload")
      .attach("theseNamesMustMatch", file)
      .on("progress", function (e) {
        //console.log(e.direction, "is done", e.percent, "%");
        //that.setState({ progress: parseInt(e.percent) });
      })
      .end(async (err, res) => {
        deffered.resolve(res.body.location);
      });
    return deffered.promise;
  };
  const updateProfile = () => {
    if (file) {
      uploadPromise(file).then(url => {
        axios
          .post("/updateProfile", {
            userId: props.userId,
            image: url,
            name: user.name,
            bio: user.bio,
          })
          .then(resp => {
            /// update
            alert("profile updated");
            window.location.reload();
          });
      });
    } else {
      axios
        .post("/updateProfile", {
          userId: props.userId,
          name: user.name,
          bio: user.bio,
        })
        .then(resp => {
          /// update
          alert("profile updated");
          window.location.reload();
        });
    }
  };
  return (
    <div>
      <div
        style={{
          height: 1200,
          width: "100%",
          paddingTop: 20,
          backgroundColor: "white",
          textAlign: "center",
        }}
      >
        <div>
          {preview && preview !== "" ? (
            <img
              style={{
                objectFit: "fill",
                height: 100,
                width: 100,
                marginBottom: 20,
                borderStyle: "solid",
                borderWidth: 3,
                padding: 5,
                borderRadius: 50,
              }}
              src={preview}
            />
          ) : (
            <img
              style={{
                height: 100,
                width: 100,
                borderStyle: "solid",
                borderWidth: 3,
                padding: 5,
                marginBottom: 20,
                borderRadius: 50,
              }}
              src={user && user.image}
            />
          )}
        </div>
        <br />

        <div>
          <input
            type="file"
            accept="image/*"
            name="preview-file"
            id="preview-file"
            className="inputfile"
            onChange={(e: any) => {
              const preview = URL.createObjectURL(e.target.files[0]);
              console.log(preview);
              setFile(e.target.files[0]);
              setPreview(preview);
            }}
          />

          <label
            style={{
              height: 40,
              borderColor: "transparent",
              borderRadius: 3,
              cursor: "pointer",
              padding: 9,
              width: 250,
              marginTop: 20,
              color: "white",
              backgroundColor: "black",
            }}
            htmlFor="preview-file"
          >
            {file ? file.name : "Choose a profile picture"}
          </label>
          <br />
          <div className="webkit">
            <div
              style={{
                width: 350,
                maxWidth: "90%",
                textAlign: "left",
              }}
            >
              <p>Email</p>
              <input
                style={{
                  width: 350,
                  maxWidth: "90%",
                  height: 40,
                  paddingLeft: 10,
                  fontSize: 20,
                  borderRadius: 3,
                }}
                disabled
                value={user && user.email}
                placeholder="Email"
              />
            </div>
            <div
              style={{
                width: 350,
                maxWidth: "90%",
                textAlign: "left",
              }}
            >
              <p>Username</p>
              <input
                style={{
                  width: 350,
                  maxWidth: "90%",
                  height: 40,
                  paddingLeft: 10,
                  fontSize: 20,
                  borderRadius: 3,
                }}
                value={user && user.name ? user.name : ""}
                placeholder="username"
                onChange={e => {
                  user.name = e.target.value;
                  setUser({ ...user });
                }}
              />
            </div>
          </div>
          <br />

          <Button
            onClick={() => {
              updateProfile();
            }}
          >
            Update
          </Button>
          <Button
            onClick={() => {
              window.localStorage.removeItem("@silla_id");
              window.location.reload();
            }}
          >
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Profile;

// import React, { useEffect, useState } from "react";
// import { Card, Container, Button } from "reactstrap";

// import axios from "axios";

// interface Iprops {
//   userId: any;
// }

// function Profile(props: Iprops) {
//   const [rentals, setRentals] = useState([]);
//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     if (props.userId) {
//       axios.post("/getProfile", { userId: props.userId }).then(resp => {
//         console.log(resp.data);
//         //setRentals(resp.data);
//       });
//     } else {
//     }
//   };

//   return (
//     <div>
//       <h1>Profile</h1>
// <Button
//   onClick={() => {
//     window.localStorage.removeItem("@silla_id");
//     window.location.reload();
//   }}
// >
//   Logout
// </Button>
//     </div>
//   );
// }

// export default Profile;
