import React, { useEffect, useState } from "react";
import { Card, Container, Button } from "reactstrap";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import axios from "axios";
import { filter } from "lodash";
import { propfind } from "superagent";
import config from "./config";
const styled = require("styled-components");
const { SketchPicker } = require("react-color");

const stripePromise = loadStripe(config.stripe);

const Styles = styled.default.div`
  .StripeElement {
    width: 100%;
  }
  .react-calendar { 
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
   }
   .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
   }
   .react-calendar__navigation button:enabled:hover,
   .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
   }
   .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
   }
   abbr[title] {
    text-decoration: none;
   }
   .react-calendar__month-view__days__day--weekend{
color: black;
   }
  
   .react-calendar__tile:enabled:hover,
   .react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #6f48eb;
    border-radius: 6px;
   }
   .react-calendar__tile--now {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    color: #69F0AE;
   }
   .react-calendar__tile--now:enabled:hover,
   .react-calendar__tile--now:enabled:focus {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    color: #6f48eb;
   }
   .react-calendar__tile--hasActive:enabled:hover,
   .react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
   }
   .react-calendar__tile--active {
    background: #6f48eb;
    border-radius: 6px;
    font-weight: bold;
    color: white;
   }
   .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus {
    background: #6f48eb;
    color: white;
   }
   .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
   }


`;

interface IProps {
  rental: any;
  type: any;
  onDone: any;
  onCancel: any;
  price: any;
}

const Booking = (props: IProps) => {
  const [dates, setDates] = useState<any>([]);
  const [paying, setPaying] = useState(false);
  const [email, setEmail] = useState<any>(null);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    fetchData();
  }, [dates]);
  const fetchData = () => {
    const userId = window.localStorage.getItem("@silla_id");
    if (userId) {
      axios
        .post("/getProfile", {
          userId,
        })
        .then(resp => {
          console.log(resp.data);
          if (resp.data.status) {
            setUser(resp.data.user);
            setEmail(resp.data.user.email);
          }
        });
    }
  };
  const InjectedCheckoutForm = (makePayment: any, amount: any, paying: any) => (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <Styles>
          <CheckoutForm
            amount={amount}
            makePayment={makePayment}
            stripe={stripe}
            elements={elements}
          />
        </Styles>
      )}
    </ElementsConsumer>
  );
  return (
    <Styles>
      <div
        style={{
          fontFamily: "Poppins",
          height: 1200,
          width: "100%",
          paddingTop: 20,
          backgroundColor: "white",
          textAlign: "center",
        }}
      >
        <Container>
          <Card
            style={{
              textAlign: "center",
              padding: 20,
            }}
          >
            <h3>Complete Booking</h3>
            <p style={{ fontWeight: "bold" }}>
              Total: ${dates.length * parseFloat(props.price)}
            </p>
            <Calendar
              minDate={new Date()}
              onChange={(d: any) => {
                //
                const _d: any = moment(d).format("MM-DD-YYYY");
                if (dates.includes(_d)) {
                  var _t = filter(dates, e => e !== _d);
                  setDates([..._t]);
                } else {
                  var t: any = dates.push(_d);
                  setDates([...dates]);
                }
              }}
              value={new Date()}
              tileDisabled={(r: any) => {
                const t = moment(r.date).format("MM-DD-YYYY");
                return props.rental.rental.bookings.includes(t) ? true : false;
              }}
              tileContent={(r: any) => {
                const t = moment(r.date).format("MM-DD-YYYY");
                return dates.includes(t) ? (
                  <div
                    style={{
                      width: "100%",
                      height: 10,
                      backgroundColor: "#69F0AE",
                    }}
                  ></div>
                ) : (
                  <div></div>
                );
              }}
            />
            <input
              value={email}
              placeholder="Email"
              style={{
                paddingLeft: 10,
                marginTop: 10,
              }}
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
            />
            <Elements stripe={stripePromise}>
              <InjectedCheckoutForm
                paying={paying}
                email={email}
                amount={dates.length * parseFloat(props.price)}
                makePayment={(paymentID: string, amount: any) => {
                  const userId = window.localStorage.getItem("@silla_id");
                  console.log(props);
                  if (props.type === "day") {
                    const d = {
                      rental: props.rental.rental,
                      type: props.type,
                      price: dates.length * parseFloat(props.price),
                      sellerId: props.rental.rental.userId,
                      buyerId: userId,
                      buyerEmail: email,
                      total: dates.length * parseFloat(props.price),
                      days: dates,
                    };
                    setPaying(true);
                    axios
                      .post("/createIntent", {
                        paymentMethodType: "card",
                        detailReq: d,
                        paymentId: paymentID,
                        amount: d.price,
                        currency: "usd",
                      })
                      .then((resp: any) => {
                        setPaying(false);
                        if (resp.data.status) {
                          alert("payment made");
                          props.onDone();
                        }
                      });
                  } else {
                    // const d = {
                    //   rental,
                    //   type,
                    //   price,
                    //   sellerId: rental.userId,
                    //   buyerId: userId,
                    //   hours: time,
                    //   total: time * price,
                    //   checkinDate: dayBooked.format("MM DD YYYY") + " " + date,
                    // };
                  }

                  /*
                  setPaying(true);
                  axios
                    .post("/makeWebPayment", {
                      rental: props.rental,
                      paymentID,
                      userId,
                      email,
                      amount,
                    })
                    .then((resp: any) => {
                      setPaying(false);
                      if (resp.data.status) {
                        alert("payment made");
                        // create order
                     
                     
                        //window.location.reload();
                    })
                    .catch((err: any) => {});
               */
                }}
              />
            </Elements>
            <button
              onClick={() => {
                props.onCancel();
              }}
            >
              cancel
            </button>
            {/* <div>
              <Button
                style={{
                  padding: 0,
                  backgroundColor: "#7540ee",
                  color: "white",
                  fontSize: 24,
                  width: 350,
                  maxWidth: "90%",
                }}
                onClick={() => {
                  window.location.href = "/account";
                }}
              >
                Get Started
              </Button>
            </div> */}
          </Card>
        </Container>
      </div>
    </Styles>
  );
};

export default Booking;
